import React from "react";
import "../App.css"; 
import SocialMediaButton from "./SocialMediaButton";
import Sidebar from "./sidebar";

const Cvname = () => {
  return (
    <div className="cv-container">
 
     


      <header className="cv-header">
        <h1>Gul Mar khan Khattak</h1>
        <h3>React JS Developer | Front-End Engineer</h3>

        <img 
          src="/gul.png" 
          alt="Profile" 
          className="profile-image"
        />
        <p>
          <strong>Email:</strong> Gul102pk@gmail.com, GMK@easypcsoft.shop | 
          <strong> Phone:</strong>  +92343-5314371  | 
          {/* <strong> LinkedIn:</strong> https://www.linkedin.com/in/gul-mar-khan-khattak-960a872b1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app  | 
          <strong> GitHub:</strong>  https://github.com/Gulmarkhan |  */}
          <strong> Location:</strong> Rawalpindi
        </p>
      </header>

     
    
      <div>
        {/* <SocialMediaButton platform="facebook" url="https://www.facebook.com/yourpage" />
        <SocialMediaButton platform="twitter" url="https://twitter.com/yourprofile" />
        <SocialMediaButton platform="linkedin" url="https://www.linkedin.com/in/yourprofile" />
        <SocialMediaButton platform="instagram" url="https://www.instagram.com/yourprofile" /> */}

        <SocialMediaButton />
      </div>
    </div>
  );
}

export default Cvname;
