import logo from './logo.svg';
import './App.css';
import Cvname from './components/Cvname';
import Sidebar from './components/sidebar';

function App() {
  return (
    <div className="App">


<div className="cv-container">

     <h1>Please Visit  EasyPcsoft.shop</h1>

      <Sidebar />
     
      <Cvname />

     
      <section className="summary">
        <h2>Summary</h2>
        <p>
          A passionate and results-driven React JS developer with [X years] of hands-on experience
          in building high-performance, scalable, and user-friendly web applications. Expertise in
          React, Redux, JavaScript (ES6+), HTML5, CSS3, and modern web technologies. Strong problem-solving
          skills, a deep understanding of front-end architecture, and a commitment to creating seamless user experiences.
        </p>
      </section>

      <section className="skills">
        <h2>Key Skills</h2>
        <ul>
          <li><strong>Core Technologies:</strong> React JS, Redux, Next.js, TypeScript, JavaScript (ES6+), HTML5, CSS3, REST APIs, GraphQL</li>
          <li><strong>State Management:</strong> Redux, Context API, React Query, Apollo Client</li>
          <li><strong>Styling:</strong> CSS, SCSS, Styled Components, Emotion, Tailwind CSS</li>
          <li><strong>Version Control:</strong> Git, GitHub, GitLab, Bitbucket</li>
          <li><strong>Build Tools:</strong> Webpack, Babel, npm, Yarn</li>
          <li><strong>Testing & Debugging:</strong> Jest, React Testing Library, Cypress, Mocha, Enzyme</li>
          <li><strong>Other Tools & Concepts:</strong> Docker, CI/CD (GitHub Actions, Jenkins), Agile/Scrum, Responsive Design, UX/UI</li>
        </ul>
      </section>

      <section className="experience">
        <h2>Professional Experience</h2>
        
        <div className="job">
          <h3>Software Engineer | Front-End Developer</h3>
          <p><strong>[Company Name]</strong> | [Location] | [Month/Year – Present]</p>
          <ul>
            <li>Developed and maintained high-performance web applications using React JS, Redux, and TypeScript.</li>
            <li>Architected reusable, maintainable component libraries, improving development efficiency by X%.</li>
            <li>Integrated RESTful APIs and GraphQL endpoints for efficient client-server interaction.</li>
            <li>Led the migration of a legacy app to React and Redux, enhancing performance and reducing load times by X%.</li>
            <li>Collaborated with UX/UI designers to implement responsive and visually appealing user interfaces using CSS3, SCSS, and Tailwind CSS.</li>
            <li>Wrote comprehensive unit and integration tests using Jest and React Testing Library, ensuring 90%+ code coverage.</li>
          </ul>
        </div>

        <div className="job">
          <h3>Junior React Developer</h3>
          <p><strong>[Company Name]</strong> | [Location] | [Month/Year – Month/Year]</p>
          <ul>
            <li>Worked on the front-end development of complex applications using React, Redux, and JavaScript.</li>
            <li>Built reusable React components optimized for performance across various devices.</li>
            <li>Integrated third-party services like OAuth and Stripe API for payment processing.</li>
            <li>Collaborated with back-end developers to integrate APIs, ensuring robust and scalable applications.</li>
          </ul>
        </div>
      </section>

      <section className="education">
        <h2>Education</h2>
        <p><strong>Bachelor of Science in Computer Science</strong> | [University Name] | [Graduation Year]</p>
        <p>Relevant coursework: Data Structures, Algorithms, Web Development, Object-Oriented Programming, Software Engineering</p>
      </section>

      <section className="certifications">
        <h2>Certifications</h2>
        <ul>
          <li>React Developer Certification – [Certification Authority] | [Year]</li>
          <li>JavaScript Algorithms and Data Structures – freeCodeCamp | [Year]</li>
          <li>AWS Certified Developer – Associate | [Year]</li>
        </ul>
      </section>

      <section className="projects">
        <h2>Projects</h2>
        <div className="project">
          <h3>E-Commerce Web Application (React, Redux, Firebase)</h3>
          <p>Built a full-stack e-commerce application with React, Redux for state management, and Firebase for authentication and database.</p>
          <ul>
            <li>Implemented features like product listings, shopping cart, and secure checkout process.</li>
            <li>Optimized performance for fast loading and smooth user experience.</li>
          </ul>
        </div>
        
        <div className="project">
          <h3>Portfolio Website (Next.js, Tailwind CSS)</h3>
          <p>Designed and developed a personal portfolio website using Next.js and Tailwind CSS, showcasing personal projects and accomplishments.</p>
          <ul>
            <li>Optimized for SEO and performance with SSR (Server-Side Rendering) in Next.js.</li>
            <li>Included features like contact form and live GitHub projects showcase.</li>
          </ul>
        </div>
        
        <div className="project">
          <h3>Task Management App (React, Node.js, MongoDB)</h3>
          <p>Created a task management app with React for the front-end and Node.js/Express for the back-end, connected to MongoDB.</p>
          <ul>
            <li>Implemented user authentication, task creation, and real-time notifications using Socket.IO.</li>
            <li>Optimized for scalability and efficient task management workflows.</li>
          </ul>
        </div>
      </section>

      <section className="open-source">
        <h2>Open Source Contributions</h2>
        <p>Contributed to [Open Source Project] on GitHub, implementing features such as [feature details].</p>
      </section>

      <section className="languages">
        <h2>Languages</h2>
        <p>Fluent in [Language(s)].</p>
      </section>

      <section className="volunteer">
        <h2>Volunteer Work</h2>
        <p>Mentor for [Coding Bootcamp Name], helping aspiring developers learn React and front-end development.</p>
      </section>
    </div>








    </div>
  );
}

export default App;
