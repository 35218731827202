import React, { useState } from 'react';
import './Sidebar.css'; // Import the CSS for styling
import { FaHome, FaUserAlt, FaEnvelope, FaCog, FaSignOutAlt, FaSearch } from 'react-icons/fa'; // Icons


function Sidebar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    return (
      <div className={`app ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        {/* Sidebar */}
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className="sidebar-header">
            <div className="profile-info">
              <div className="profile-pic">
                <img src="https://via.placeholder.com/50" alt="G M Khan " />
              </div>
              {/* <div className="profile-details">
                <span className="username">John Doe</span>
                <span className="user-status">Online</span>
              </div> */}
            </div>
          </div>
  
          <ul className="sidebar-menu">
            <li className="menu-item active">
              <FaHome className="menu-icon" />
              <span className="menu-text">Home</span>
            </li>
            <li className="menu-item">
              <FaUserAlt className="menu-icon" />
              <span className="menu-text">Profile</span>
            </li>
            <li className="menu-item">
              <FaEnvelope className="menu-icon" />
              <span className="menu-text">Messages</span>
            </li>
            <li className="menu-item">
              <FaCog className="menu-icon" />
              <span className="menu-text">Settings</span>
            </li>
            <li className="menu-item">
              <FaSignOutAlt className="menu-icon" />
              <span className="menu-text">Logout</span>
            </li>
          </ul>
        </div>
  
        {/* Main Content */}
        <div className="content">
          <div className="navbar">
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              ☰
            </button>
            <div className="search-bar">
              <FaSearch className="search-icon" />
              <input type="text" placeholder="Search..." />
            </div>
          </div>
  
          
        </div>
      </div>
    );
  }
  

export default Sidebar;
